'use client';

import { ROUTES } from '@constants/routes.constants';
import { ErrorPage } from '@ui/components';
import { useEffect } from 'react';
import { useLogErrorToRollbar } from './providers';

interface NotFoundProps {}

export default function NotFound(props: NotFoundProps) {
  const logErrorToRollbar = useLogErrorToRollbar();

  const error = new Error('404 Not Found');

  useEffect(() => {
    logErrorToRollbar(
      {
        error,
        context: {
          route: typeof window !== 'undefined' ? window.location.pathname : '',
          userAgent: typeof navigator !== 'undefined' ? navigator.userAgent : '',
          referrer: typeof document !== 'undefined' ? document.referrer : '',
          queryParams: typeof window !== 'undefined' ? window.location.search : '',
          additionalContext: {
            url: typeof window !== 'undefined' ? window.location.href : '',
            previousUrl: typeof document !== 'undefined' ? document.referrer : '',
            browserInfo:
              typeof navigator !== 'undefined'
                ? {
                    userAgent: navigator.userAgent,
                    language: navigator.language,
                  }
                : {},
          },
        },
      },
      'warning',
    );

    console.error('Error details:', error);
  }, [error, logErrorToRollbar]);

  return (
    <ErrorPage
      title="404"
      type="404"
      message="This Is Not the Page You Are Looking For"
      buttonText="Go to Main"
      href={ROUTES.creators}
    />
  );
}
